import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'portal/_services';

@Component({
    moduleId: module.id,
    templateUrl: 'portal.component.html'
})

export class PortalComponent implements OnInit {
    currentUser: any;

    constructor(private analyticsService: AnalyticsService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.analyticsService.trackEvent('portal loaded', 'portal loaded into view', 'generic');
    }

    ngOnInit() {
        this.analyticsService.trackEvent('portal loaded', 'portal loaded into view', 'generic');
    }
}
