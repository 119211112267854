import { NgZone } from '@angular/core';
import * as i0 from "@angular/core";
export class AnalyticsService {
    constructor(ngZone) {
        this.ngZone = ngZone;
    }
    trackEvent(eventName, eventDetails, eventCategory) {
        this.ngZone.runOutsideAngular(() => {
            gtag('event', eventName, {
                'event_category': eventCategory,
                'event_label': eventName,
                'value': eventDetails
            });
        });
        console.log('tracked event: ' + eventName);
    }
}
AnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.inject(i0.NgZone)); }, token: AnalyticsService, providedIn: "root" });
