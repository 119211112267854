﻿//const version = require('../version.js');

import * as version from "./version";

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app.module';
import { environment } from '../environments/environment';
import { enableProdMode } from "@angular/core";

console.log("application version " + version.version_info.hostVersion + "/" + version.version_info.version + ", " + version.version_info.date + ", build: " + version.version_info.buildDate);

if ( environment.production ) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
