import { Injectable, NgZone } from '@angular/core';

declare var gtag : any;

@Injectable({providedIn: 'root'})
export class AnalyticsService {
  constructor(private ngZone: NgZone) {}

  trackEvent(eventName: string, eventDetails: string, eventCategory: string) {
    this.ngZone.runOutsideAngular(() => {
      gtag('event', eventName, {
      'event_category': eventCategory,
      'event_label': eventName,
      'value': eventDetails
      })
    })
    console.log('tracked event: ' + eventName)
  }
}